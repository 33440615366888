<h2 class="popup-heading" mat-dialog-title>{{ title }} {{ show.custom_name + " [" + show.show_code + "]" }}
    <mat-dialog-actions>
        <button mat-stroked-button (click) = "close()">Close</button>
    </mat-dialog-actions>
</h2>
<mat-dialog-content class="mat-typography">
<div class="announcement-music-box">
        <div class="announcement-box">
                <mat-form-field class="announcement-field" appearance="outline">
                    <textarea type="text" matInput [formControl]="announcementComment" maxlength="300" cdkTextareaAutosize cdkAutosizeMinRows="1"></textarea>
                </mat-form-field>
                <div class="announcement-footer">
                    <div class="announcement-chr-limit">
                        <p>0 to 300 max character</p>
                    </div>
                    <div class="announcement-btn">
                        <button class="popup-save" (click)="clear()" mat-flat-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" [disabled]="processing">Clear <mat-icon *ngIf="processing">
                            <mat-spinner class="accent-spinner" diameter="20"></mat-spinner>
                        </mat-icon></button>
                        <button class="popup-save" (click)="send()" mat-flat-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" type="submit" [disabled]="processing">Send <mat-icon *ngIf="processing">
                            <mat-spinner class="accent-spinner" diameter="20"></mat-spinner>
                        </mat-icon></button>
                    </div>
                </div>
                <p class="announcement-txt">Enter an announcement above to display on the {{ siteName }} audience app. This message is sent in real-time and displayed immediately to everyone. </p>
        </div>
</div>
</mat-dialog-content>