import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent implements OnInit {
  onConfirm = new EventEmitter();
  public processing;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.processing = false;
  }

  ngOnInit(): void {
  }

  confirm() {
    this.processing = true;
    this.onConfirm.emit();
  }

}
