import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-revoke-confirmation',
  templateUrl: './revoke-confirmation.component.html',
  styleUrl: './revoke-confirmation.component.scss'
})
export class RevokeConfirmationComponent {
  constructor(public dialogRef: MatDialogRef<RevokeConfirmationComponent>) {}

  onYesClick(): void {
    this.dialogRef.close(true);
  }
}
