<div class="content">
		<mat-card appearance="outlined" class="example-card">
				<mat-card-content>
					<p>You can use collaborators to allow other individuals to have access to your shows both to edit them, and also to start and control the show from both the iOS and Android apps. When logged into the account, they will see a Switch User button in the upper left of the UI to toggle between their current account and your account. In addition, if they access a show code that you own from the {{ siteName }} app, they will be given the option to both listen, and also control the show.</p>
				</mat-card-content>
			</mat-card>
	<div class="my-show-table">
		<div class="btn">
			<mat-form-field class="table-search">
				<mat-label>Search collaborators...</mat-label>
				<input matInput placeholder="Enter search term here" #search [(ngModel)]="s">
			</mat-form-field>
			<div class="shows-btn">
				<button mat-raised-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" class="add-btn" (click)="addEdit('')"><span style="line-height: 24px;margin-right: 5px;display: inline-block;">Add New Collaborator</span> <mat-icon>add_box</mat-icon></button>
			</div>
		</div>
		<div class="mat-elevation-z8">
			<table mat-table [dataSource]="dataSource" matSort matSortActive="email" matSortDirection="asc">

				<!-- Position Column -->
				<ng-container matColumnDef="email">
					<th mat-header-cell *matHeaderCellDef mat-sort-header class="show-sm" width="50%"> Email </th>
					<td mat-cell *matCellDef="let element" class="show-sm"> {{element.email}} </td>
				</ng-container>

				<!-- <ng-container matColumnDef="user_emails">
					<th mat-header-cell *matHeaderCellDef mat-sort-header width="50%"> COLLABORATORS Emails </th>
					<td mat-cell *matCellDef="let element"><span [innerHtml]="element.email.split('\n').join('<br>')"></span></td>
				</ng-container> -->

				<ng-container matColumnDef="action">
					<th mat-header-cell *matHeaderCellDef class="show-sm action-col" width="7%"> Action </th>
					<td mat-cell *matCellDef="let element" class="show-sm action-col">
						<div class="table-select">
							<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="collaborators Actions" [disabled]="element.processing">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu">
								<!-- <button mat-menu-item (click)="addEdit(element)">
									<span>Edit</span>
								</button> -->
								<button mat-menu-item (click)="delete(element.id, element.email)">
									<span>Delete</span>
								</button>
							</mat-menu>
						</div>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
				<tr class="mat-row" *matNoDataRow>
					<td colspan="7" class="mat-cell show-sm">{{ loading ? 'Loading...':'No records found.'}}</td>
				</tr>
			</table>
			<mat-paginator [class]="total_collaborators <= 1 ? 'hidden_dropdown':''" [pageSizeOptions]="[10, 50, 100, 250, 500]" aria-label="Select page of the records."></mat-paginator>
		</div>
	</div>
</div>
