<h2 class="popup-heading" mat-dialog-title>{{ title }}</h2>
<mat-dialog-content class="mat-typography">
    <mat-form-field appearance="fill" class="single-field">
        <mat-label>Show Code</mat-label>
        <input matInput [(ngModel)]="show_code">
    </mat-form-field>
    <div *ngFor="let error of errors" class="errors">
        <span>{{ error }}</span>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="cancel-popup" mat-stroked-button (click) = "close()">Cancel</button>
    <button class="popup-save" mat-flat-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" (click) = "getSingleShow()" cdkFocusInitial>Show <mat-icon *ngIf="processing">
        <mat-spinner class="accent-spinner" diameter="20"></mat-spinner>
    </mat-icon></button>
  </mat-dialog-actions>
