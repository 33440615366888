import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Show} from "../../interfaces/show.interface";
import {BrandsService} from "../../services/brands.service";
import {Font} from "ngx-font-picker";

@Component({
  selector: 'app-preview-brand',
  templateUrl: './preview-brand.component.html',
  styleUrls: ['./preview-brand.component.scss']
})
export class PreviewBrandComponent implements OnInit {
	brand: any;
	font: any = new Font();
	show: Show;
	brandFetched = false;
  constructor(@Inject(MAT_DIALOG_DATA) data: any,
							public brandsService: BrandsService) {
		this.show = data.show
		this.font.family = 'Roboto';
		if(this.show.brand_id) {
			this.brandsService.get(this.show.brand_id).subscribe((response: any) => {
				if(response.status == 'success') {
					this.brand = response.brand;
					this.font.family = this.brand.font_family;
				}
				this.brandFetched = true
			});
		}
		else{
			this.brandFetched = true
		}
	}

  ngOnInit(): void {

  }

	versionedImage(url: string){
		return url + "?v=" + Math.random();
	}

}
