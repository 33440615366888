import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserReportService } from '../../services/user-reports.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-exclude-email',
  templateUrl: './exclude-email.component.html',
  styleUrls: ['./exclude-email.component.scss']
})
export class ExcludeEmailComponent {
  emails: string = '';
  processing: boolean = false;
  loading = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { emails: string },
    public userReportService: UserReportService,
    public _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ExcludeEmailComponent>
  ) {
  }
  ngOnInit() {
    this.loadExcludedEmails();
  }
  loadExcludedEmails() {
    this.processing = true
    this.userReportService.getexcludedEmail().subscribe(
      (response: any) => {
        if (response?.success && Array.isArray(response?.emails)) {
          this.emails = response.emails.map((emailObj: { email: string }) => emailObj.email).join('\n') || '';
        } else {
          this.emails = '';
        }
        this.processing= false
      },
      (error) => {
        console.error("Error loading excluded emails:", error);
        this._snackBar.open('Failed to load excluded emails.', 'Dismiss');
      }
    );
  }  
  confirm() {
    console.log("Emails to exclude:", this.emails);
    this.processing = true;

    this.userReportService.excludeEmail(this.emails).subscribe(
      () => {
        this.processing = false;
        this._snackBar.open('Emails Excluded successfully.', 'Dismiss');
        setTimeout(() => {
          this._snackBar.dismiss();
        }, 3000);

        this.dialogRef.close();
      },
      (error) => {
        console.error("Error excluding emails:", error);
        this.processing = false;
      }
    );
  }
}