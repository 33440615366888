<mat-dialog-content class="mat-typography">
    <div>
        <p>Are you sure you want to revoke this API Key? No third-party application can access the API Key using this key.</p>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div mat-dialog-actions>
        <button class="cancel-popup" mat-stroked-button mat-dialog-close>No</button>
        <button class="popup-save" mat-flat-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" (click)="onYesClick()">Yes</button>
    </div>
</mat-dialog-actions>