import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from "../services/auth/auth.service";

@Injectable({
	providedIn: 'root'
})
export class AuthGuard  {
	constructor(private authService: AuthService, private router: Router) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const url: string = state.url;
		return this.authService.authorize().then(result => {
			if (!result) {
				this.authService.redirectUrl = url;
				return this.router.parseUrl('/login');
			}
			return true;
		});
	}
}
