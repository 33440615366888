import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import {Observable} from "rxjs";
import {Show} from "../interfaces/show.interface";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserReportService {
  constructor(private http:HttpClient) { }

  index(
    filters: any, orderBy = '', order = 'asc',
    pageNumber = 0, pageSize = 5):  Observable<any> {
		let params = new HttpParams();
  	Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);
		return this.http.get(environment.apiUrl + '/shows/available', { //?email=ssmith@cobrafiringsystems.com
      params: params
    });
  }
	getUserReport(email: any, website_id: any): Observable<any> {
		return this.http.post(environment.apiUrl + '/shows/user-report', {
			'email': email,
			'website_id': website_id,
		});
	}
	getUserReportDetails(email: any, website_id: any): Observable<any> {
		return this.http.post(environment.apiUrl + '/shows/user-report-details', {
			'email': email,
			'website_id': website_id
		});
	}
	excludeEmail(emails: any): Observable<Object>{
		return this.http.put(environment.apiUrl + '/shows/exclude_emails/emails', {
			'emails': emails
		});
	}
	getexcludedEmail(): Observable<Object>{
		return this.http.get(environment.apiUrl + '/shows/excluded_emails/emails', {
		});
	}
}
