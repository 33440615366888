<h2 class="popup-heading single-show" mat-dialog-title>
	{{ show.custom_name + " [" + show.show_code + "]" }}{{(show.copy_of ? ' - Copy of ' + show.copy_of : '' )}}
	<span>{{show.created_date_ts|date}} <span>| {{show.platform === 'AUDIO_APP' ? siteName : (show.platform === 'CSC' ? 'COBRA': show.platform)}}</span>{{show.timecode_channel === 'right' ? ' | SMPTE (Right Channel)' : (show.timecode_channel === 'processing' ? ' | Processing SMPTE...' : '')}}</span>
	<span [class]="!loaded ? 'transparent' : ''" cdkFocusRegionstart>{{ show?.audio_file_name }}.mp3</span>
	<mat-dialog-actions>
		<!--<button *ngIf="show.platform==='AUDIO_APP'" mat-button (click)="editAudioFile()">{{ !show.audio_file_id ? 'Add' : 'Replace' }} Audio File</button>-->
		<button mat-stroked-button (click) = "close()">Close</button>
	</mat-dialog-actions>
</h2>
<mat-dialog-content class="mat-typography">
	<div *ngIf="show.audio_file_id && !loadingError && show.audio_file_name" class="wave-container">
		<div id="waveform" [class]="!loaded ? 'transparent' : ''"></div>
		<div id="wave-timeline" [class]="!loaded ? 'transparent' : ''"></div>
		<!--<div class="timer" [hidden]="!loaded">{{ clockTime | date:'HH:mm:ss.S' }}</div>-->
		<div class="timer" [hidden]="!loaded">{{ seekTime | date:'HH:mm:ss.S' }}</div>
		<div class="wave-controls" [hidden]="!loaded">
			<button mat-raised-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" [class]="wave && wave.isPlaying() ? 'hidden' : ''" (click)="playConfirm()" aria-label="Play Audio" [disabled]="updatingShow">{{ wave && wave.getCurrentTime() > 0 && played ? 'RESUME' : 'START'}} SHOW</button>
			<button mat-raised-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" [class]="(!wave || !wave.isPlaying()) ? 'hidden' : ''" (click)="pauseConfirm()" aria-label="Puase Audio" [disabled]="updatingShow">PAUSE SHOW</button>
			<button mat-raised-button color="warn" [disabled]="!wave || !wave.getCurrentTime() || updatingShow" (click)="stopConfirm()" aria-label="Stop Audio">STOP SHOW</button>
		</div>
		<div class="announcement-music-box">
				<div class="music-box" *ngIf="loaded">
					<h4>Adjust Audio Alignment</h4>
					<div class="musix-controls">
						<button mat-raised-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" (click)="adjust(false)"> too early</button>
						<h5>{{ ( (adjustment) > 0 ? '+' : '' ) + (adjustment).toFixed(1) + 's' }}</h5>
						<button mat-raised-button [ngStyle]="{'background-color': 'var(--accent-color)', 'color': 'var(--light-color)'}" (click)="adjust(true)"> too late</button>
					</div>
					<p>When playing audio from a web browser, you can fine tune the alignment using the below controls. Please note this technology is currently in BETA with ongoing optimizations in development.</p>
					<p>Use the <b style="font-family: Arial">Settings > Global Offset Adjustment</b> to apply a general offset in addition to the fine tune controls above.</p>
				</div>
				<div class="announcement-box" *ngIf="loaded">
						<h4>Announcements</h4>
						<mat-form-field class="announcement-field" appearance="outline">
							<textarea type="text" matInput [formControl]="announcementComment" maxlength="300" cdkTextareaAutosize cdkAutosizeMinRows="1"></textarea>
						</mat-form-field>
						<div class="announcement-footer">
							<div class="announcement-chr-limit">
								<p>0 to 300 max character</p>
							</div>
							<div class="announcement-btn">
								<button class="popup-save" (click)="clear()" mat-flat-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" [disabled]="processing">Clear <mat-icon *ngIf="processing">
									<mat-spinner class="accent-spinner" diameter="20"></mat-spinner>
								</mat-icon></button>
								<button class="popup-save" (click)="send()" mat-flat-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" type="submit" [disabled]="processing">Send <mat-icon *ngIf="processing">
									<mat-spinner class="accent-spinner" diameter="20"></mat-spinner>
								</mat-icon></button>
							</div>
						</div>
						<p class="announcement-txt">Enter an announcement above to display on the {{ siteName }} audience app. This message is sent in real-time and displayed immediately to everyone. </p>

				</div>
		</div>
		<div class="countdown" *ngIf="countdown > 0">
			<span>
				Show starts in <b>{{ countdown }}</b> seconds<br>
				<!-- <button (click)="cancelStart()" mat-button [disabled]="processing">Cancel Show Start <mat-icon *ngIf="processing">
					<mat-spinner color="primary" diameter="20"></mat-spinner>
				</mat-icon></button> -->
			</span>
		</div>
		<div class="progress-bar-container" *ngIf="!loaded">
			<span *ngIf="progress < 100">{{ this.newAudioFile ? 'Retrieving new audio file ' + (show?.audio_file_name) +'.mp3. Please wait...':'Loading...' }} <span *ngIf="fileInfo && progress > 0">{{ progress }}%</span><span *ngIf="!fileInfo || progress == 0"><mat-progress-spinner mode="indeterminate" diameter="24"></mat-progress-spinner></span></span>
			<span *ngIf="progress === 100">Loading show, please wait...</span>
			<mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
		</div>
	</div>
	<div *ngIf="show.audio_file_id && loadingError">
		<p>Error loading audio file. Either the file not found or it is corrupted. Click edit audio to upload new audio file.</p>
	</div>
	<div *ngIf="!show.audio_file_id">
		<p>No audio file is attached to this show. For shows created from the PYROCAST platform, please click Add Audio from the show listing to add an audio file.</p>
		<p>If the show was created from COBRA or IGNITE, please use COBRA Show Creator or IGNITE Show Designer to load the audio file directly. Once loaded, the audio file will appear in PYROCAST automatically.</p>
	</div>
	<div *ngIf="show.audio_file_id && !show.audio_file_name">
		<p>Unfortunately no audio file is associated with this show. Please contact the show owner or <a href="mailto:help@pyrocast.com">help&#64;pyrocast.com</a> if you need further assistance..</p>
	</div>

</mat-dialog-content>
