<div class="content single-show-content" *ngIf="show">
	<div style="text-align: center"><img [src]="'assets/img/' + logo" [style]="!isPyrocast ? 'width: 200px' : 'width: 200px'" /></div>
	<p style="text-align: center; margin-bottom: 30px;">In order to install the {{ siteName }} app for show code: {{show?.show_code?.toUpperCase()}}, please use the QR code below using a mobile device.<br><br> You can also install the {{ siteName }} app for both iOS <a href="{{ iosApp }}">{{ iosApp }}</a> or Android <a href="{{ androidApp }}">{{ androidApp }}</a></p>
	<img [src]="this.qrcodeUrl" style="width: 120px; display: block; margin:0 auto;" />
</div>
<div class="content single-show-content" *ngIf="!show">
	<div class="login" *ngIf="!loaded">
		<div>
			Please wait...
			<mat-progress-bar mode="indeterminate"></mat-progress-bar>
		</div>
	</div>
	<div *ngIf="loaded">
		<p>Unfortunately this show does not exist. Please contact the show owner or <a href="mailto:{{email}}">{{email}}</a> if you need further assistance..</p>
	</div>
</div>
