<h2 class="popup-heading" mat-dialog-title>Confirmation</h2>
<div class="password-container">
<mat-dialog-content class="mat-typography">
    <div class="delete-view">
        <p>Are you sure you want to delete this item?</p>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="cancel-popup blue-color" mat-stroked-button [disabled]="processing" mat-dialog-close>No</button>
  <button class="popup-save red-color" mat-flat-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" (click)="confirm()" [disabled]="processing" cdkFocusInitial>
    Yes
    <mat-icon *ngIf="processing">
      <mat-spinner color="accent" diameter="20"></mat-spinner>
    </mat-icon>
  </button>
</mat-dialog-actions>
</div>