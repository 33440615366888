<div class="show-sequence">
    <h2 class="popup-heading" mat-dialog-title>Show Playlists</h2>
    <mat-progress-bar mode="indeterminate" *ngIf="loading" style="padding: 20px 0"></mat-progress-bar>
    <mat-dialog-content class="mat-typography" *ngIf="!loading">
        <div>
            <h2>Master Checksum: <span>{{ show.checksum || '--' }}</span></h2>
            <h2>Master Playlist: <span>{{ show.playlist || '--' }}</span></h2>
        </div>
        <table mat-table [dataSource]="showSequence">
            <ng-container matColumnDef="file_name">
                <th mat-header-cell *matHeaderCellDef> Audio File Name </th>
                <td mat-cell *matCellDef="let sequence"> {{sequence.audio_filename}} </td>
            </ng-container>
            <ng-container matColumnDef="file_id">
                <th mat-header-cell *matHeaderCellDef> File Id </th>
                <td mat-cell *matCellDef="let sequence"> {{sequence.audio_file_id}} </td>
            </ng-container>
            <ng-container matColumnDef="last_updated">
                <th mat-header-cell *matHeaderCellDef> Last Updated </th>
                <td mat-cell *matCellDef="let sequence"> {{ sequence.ts | date:'medium'}} </td>
            </ng-container>
            <ng-container matColumnDef="checksum">
                <th mat-header-cell *matHeaderCellDef> Checksum </th>
                <td mat-cell *matCellDef="let sequence"> {{sequence.checksum}} </td>
            </ng-container>
            <ng-container matColumnDef="playlist">
                <th mat-header-cell *matHeaderCellDef> Playlist </th>
                <td mat-cell *matCellDef="let sequence"> {{sequence.playlist}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>      
        </table>
    </mat-dialog-content>
</div>