import { HttpErrorResponse } from '@angular/common/http';
import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from "@angular/material/dialog";
import { ShowsService } from 'src/app/services/shows.service';

@Component({
  selector: 'app-my-single-show',
  templateUrl: './my-single-show.component.html',
  styleUrls: ['./my-single-show.component.scss']
})
export class MySingleShowComponent implements OnInit {

	singleShowData = new EventEmitter();
  public title = '';
	public show_code = '';
	errors: any;
	processing;

	constructor(private dialogRef: MatDialogRef<MySingleShowComponent>,
								@Inject(MAT_DIALOG_DATA) data:any,
								private showsService: ShowsService,
								public dialog: MatDialog) {
		this.title = data.title;
		this.processing = false;
		}

	ngOnInit(): void {}

	close() {
		this.dialogRef.close('cancelDialog');
	}

	getSingleShow(){
		this.processing = true;
		this.showsService.getSingleShow( this.show_code ).subscribe((response:any) => {
			if(response) {
				this.singleShowData.emit(response);
				this.dialogRef.close();
			}
			else{
				this.errors = "No Data";
			}
			this.processing = false;
		},
		(errorResponse: HttpErrorResponse) => {
			console.log(errorResponse.error['errors']);
			this.errors = errorResponse.error['errors'];
			console.log(this.errors);
			this.processing = false;
		});
	}
}
