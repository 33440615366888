import { Component, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ShowsService } from 'src/app/services/shows.service';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-show-sequence',
  templateUrl: './show-sequence.component.html',
  styleUrl: './show-sequence.component.scss'
})
export class ShowSequenceComponent {
  displayedColumns: string[] = ['file_name', 'file_id', 'last_updated', 'checksum', 'playlist'];
  showSequence = new MatTableDataSource<any>();
  show: any;
  loading = false;
  constructor(public showsService: ShowsService,
    @Inject(MAT_DIALOG_DATA) data:any) {
      this.show = data.show
  }
  ngOnInit(): void {
    this.loading = true;
    this.showsService.getShowSequence(this.show.show_id).subscribe(
      (data: any) => {
        this.loading = false;
        if (data.success && data.sequence) {
          this.showSequence.data = data.sequence;
        } else {
          console.error('No sequence data available');
        }
      },
      (error) => {
        console.error('Error fetching show sequence data:', error);
      }
    );
  }
}
