import {ChangeDetectorRef, Component, EventEmitter, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Show} from "../../interfaces/show.interface";
import WaveSurfer from 'wavesurfer.js';
import {ShowsService} from "../../services/shows.service";
import TimelinePlugin from "wavesurfer.js/dist/plugins/timeline";
import {FileUploadPopupComponent} from "../file-upload-popup/file-upload-popup.component";
import {DatePipe} from "@angular/common";
import {ConfirmPopupComponent} from "../confirm-popup/confirm-popup.component";
import { UntypedFormControl, Validators } from '@angular/forms';
import {DatabaseService} from "../../services/database.service";
import {interval, Subject} from "rxjs";
import {InactivePopupComponent} from "../inactive-popup/inactive-popup.component";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-audio-preview-popup',
  templateUrl: './audio-preview-popup.component.html',
  styleUrls: ['./audio-preview-popup.component.scss']
})
export class AudioPreviewPopupComponent implements OnInit {

	announcementComment = new UntypedFormControl('', Validators.required);
	public processing = false;

	onProcessSMPTE = new EventEmitter();
	onProcessedSMPTE = new EventEmitter();
	public show:Show;
	public fileInfo:any;
	public fileChanged = false;
	public loaded = false;
	public progress = 0;
	public retried = 0
	public loadingError = false
	public fileError = false
	public newAudioFile = false;
	public timeDifference = 0;
	public timeOffset = ((new Date()).getTimezoneOffset() + (this.isDST() ? 60 : 0) )*60*1000;
	public seekTime = new Date(this.timeOffset);
	public clockTime = new Date();
	//public secondsCounter = interval(1000);
	public timestampStartTime = 0;
	public timestampStartTime2 = 0;
	public secondsCounterTimeout = 0;
	public subscription:any;
	public firebaseSubscription:any;
	public countdown = 0;
	public adjustment = 0;
	public globalAdjustment = 0;
	public defaultAdjustment = 0;
	public updatingShow = false;
	public updatingShowCF = false;
	public timeout:any = null;
	public interactionAllowed = true;
	public interacting = false;
	public played = false;
	wave: WaveSurfer | undefined;
	public userActivity: any;
	userInactive: Subject<any> = new Subject();
	confirmingClose = false;
	confirmedClose = false;
	public isPublic = false;
	public isPyrocast = true;
	public siteName = 'Pyrocast';
	public isTune2 = false;
	countdownTimer: any;
	public audioLength = 1;
	public socket: WebSocket | undefined;
	socketClock = 0;
	webSocketMessageCount = 0;
  constructor(private dialogRef: MatDialogRef<AudioPreviewPopupComponent>,
							@Inject(MAT_DIALOG_DATA) data:any,
							private showService: ShowsService,
							private cdr: ChangeDetectorRef,
							public dialog: MatDialog,
							public datepipe: DatePipe,
							private db: DatabaseService) {
		if(window.location.hostname == environment.skyDomain){
			this.isPyrocast = false;
			this.siteName = 'SkyStreamEvents'
		}
		if(window.location.hostname == environment.tune2Domain || window.location.hostname == environment.tune2Domain2){
			this.isPyrocast = false;
			this.isTune2 = true;
			this.siteName = 'Tune2'
		}
		if(window.localStorage.getItem('adjustment')){
			this.globalAdjustment = Number(window.localStorage.getItem('adjustment'));
		}
  	this.show = data.show;
		if(data.public){
			this.isPublic = true;
		}
		if(this.show.created_date){
			this.show.created_date_ts = new Date(this.show.created_date.split(' ')[0] + "T" + this.show.created_date.split(' ')[1] + "+00:00");
		}
		this.db.getData( this.show.show_code ).subscribe((response:any) => {
			if(response && response.messageText) {
				this.announcementComment.setValue(response.messageText);
			}
		});
  	this.updateTimer();
		this.setTimeout();
		this.userInactive.subscribe(() => {
			clearTimeout(this.userActivity);
			this.confirmingClose = true;
			const dialogRef = this.dialog.open(InactivePopupComponent,{
				id: 'inactive-popup',
				maxWidth: '600px',
				width: '100%',
				data: {
					title: 'Are you still there?',
					message: 'We have detected that you were inactive for 30 minutes. This page will automatically close in '
				}
			});
			dialogRef.componentInstance.onConfirm.subscribe(() => {
				this.confirmedClose = true;
				dialogRef.close();
				this.close();
			})
			dialogRef.afterClosed().subscribe(() => {
				if(!this.confirmedClose){
					this.setTimeout();
				}
				this.confirmingClose = false;
				this.confirmedClose = false;
			});
		});
		this.processing = false;
	}

	isDST() {
		let d = new Date();
		let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
		let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
		return Math.max(jan, jul) !== d.getTimezoneOffset();
	}

  ngOnInit(): void {
  	this.loadFileInfo();
	this.onProcessedSMPTE.subscribe( show => {
		this.loadFileInfo();
	});
  	this.dialogRef.beforeClosed().subscribe( () => {
		if (this.wave) {
			this.wave.stop();
		}
	});
  	// this.firebaseSubscription = this.db.getShowStatus( this.show.show_code ).subscribe((response:any) => {
	// 	console.log("Firebase: " + response)
	// 	this.processSyncData(response, true);
	// });
	// this.timestampStartTime = (+ new Date());
	// this.showService.getTimestamp().subscribe(response => {
	// 	this.timestampStartTime -= (+ new Date());
	// 	this.timestampStartTime2 = (+ new Date());
	// 	this.showService.getTimestamp().subscribe(response => {
	// 		this.timestampStartTime2 -= (+ new Date());
	// 		console.log(((this.timestampStartTime+this.timestampStartTime2)/2) + " milliseconds");
	// 		console.log((+(new Date())  - this.timeDifference ) % 1000);
	// 		let ts = (+ new Date());
	// 		this.timeDifference = ts - response.timestamp + (this.timestampStartTime+this.timestampStartTime2)/2;
	// 		console.log(this.timeDifference + " milliseconds");
	// 		// setTimeout(() => {
	// 		// 	this.secondsCounterFunc();
	// 		// }, (+(new Date())  - this.timeDifference ) % 1000);
	// 		//if(this.timeDifference)
	// 		console.log("Time Difference is: " + this.timeDifference);
	// 		this.secondsCounterFunc();
	// 		//this.timeDifference = 0;
	// 		//console.log(this.timeDifference);
	// 	});
	// });
	/*this.subscription = this.secondsCounter.subscribe(n => {
		if(this.loaded){
			setTimeout(() => {
				this.showService.getShowJson(this.show).subscribe(data => {
					this.processSyncData(data);
				})
				this.clockTime = new Date(+(new Date()) - this.timeDifference);
			}, (+(new Date())  - this.timeDifference ) % 1000);
		}
	});*/
	this.socket = new WebSocket('wss://beta.api.pyrocast.com/ws');
	this.socket.onerror = (error) => {
		console.error('WebSocket error:', error);
	};

	this.socket.onclose = () => {
		console.log('WebSocket connection closed');
	};
	const tsValues = {
		T1_Client: 0,
		T2_Server: 0,
		T3_Client: 0,
		RTT: 0,
		T_to: 0
	}
	if(this.socket){
		this.socket.onmessage = (event) => {
			tsValues.T3_Client = (+ new Date())
			tsValues.RTT = tsValues.T3_Client - tsValues.T1_Client
			const data = JSON.parse(event.data);
			console.log(data);
			if(this.socketClock == 0){ // Clock Start Time on First Message
				this.socketClock = +new Date();
				setInterval(() => {
					this.socketClock += 50;
				}, 50);
			}
			if(this.webSocketMessageCount < 5){
				this.timeDifference = (+new Date() - data.timestamp)
				tsValues.T2_Server = data.timestamp;
				tsValues.T_to = this.timeDifference;
				console.log("Time Difference is: " + this.timeDifference);
			}
			this.webSocketMessageCount++;
			// this.db.setTimeData(this.show.show_code, tsValues);
			console.log("Client Time: " + (+new Date()) + "  Server Time: " + data.timestamp + "  Difference: " + (+new Date() - data.timestamp)+ "  Clock Difference: " + (this.socketClock - data.timestamp));
		};
	}
	this.socket.onopen = () => {
		console.log('WebSocket connect:');
		if(this.socket){
			this.timestampStartTime = (+ new Date());
			tsValues.T1_Client = this.timestampStartTime;
			this.socket.send("timestamp");
		}
	};
	// this.showService.getTimestamp().subscribe(response => {
	// 	let ts = (+ new Date());
	// 	this.timeDifference = ts - response.timestamp;
	// 	console.log(this.timeDifference);
	// });
  }
  secondsCounterFunc(){
	  //return;
  	if(this.secondsCounterTimeout){
		clearTimeout(this.secondsCounterTimeout);
	}
	this.secondsCounterTimeout = setTimeout(() => {
		this.secondsCounterFunc();
	}, 1000);
	this.showService.getShowJson(this.show).subscribe(data => {
		this.processSyncData(data);
	})
	//this.clockTime = new Date(+(new Date()) - this.timeDifference);
  }
  processSyncData(data: string, skipUpdatingShowCheck = false){
		if(this.updatingShowCF && !skipUpdatingShowCheck) return;
		const status:any = data.split(',');
		if(this.wave){
			if(this.show.looping){
				console.log(this.wave.getCurrentTime()*1000-(((+ new Date()) - Number(status[1]))) % (this.wave.getDuration()*1000));
			}
			else{
				console.log(this.wave.getCurrentTime()*1000-(+(new Date()))+Number(status[1])-Number(status[2]))
			}
		}
		this.show.status = status[0];
		if(status[0] === 'play' || status[0] === 'resume'){
			if(this.countdown == 0 && !this.wave?.isPlaying()){
				let t = Number(status[2]);
				const playTime = (+ new Date()) - Number(status[1]);
				if(this.wave && this.wave.getDuration()){
					if(!this.show.looping && ((+ new Date()) - Number(status[1])) > 0 && ((+ new Date()) - Number(status[1])) < this.audioLength*1000 || this.show.looping && Number(status[1]) < (+ new Date())){
						let seekTime = (+(new Date()) - Number(status[1]) + Number(status[2])) % (this.audioLength*1000)
						console.log("Duration:" + this.wave.getDuration());
						console.log("Seek Time:" + seekTime);
						console.log("Now:" + (((+(new Date()) - 1722366000001 )%27193.4693877551)/1000));
						this.wave.setTime((seekTime - this.timeDifference)/1000);
						this.wave.play()
						// t = (t + seekTime - this.timeDifference + (this.adjustment + this.globalAdjustment + this.defaultAdjustment) * 1000) / (1000 * this.wave.getDuration())
						// if(t < 1 && t >= 0 ) {
						// 	console.log("received '" +  status[0] + "' at " + status[1] + " calculated " + (Number(status[1]) - this.timeDifference + (this.adjustment + this.globalAdjustment + this.defaultAdjustment) * 1000) + ". Playing now...");
						// 	//this.wave.seekTo(t);
						// 	this.wave.setTime((seekTime + Number(status[2]) - this.timeDifference)/1000);
						// 	this.wave.play()
						// }
						// else{
						// 	console.log("received '" +  status[0] + "' at " + status[1] + " calculated " + (Number(status[1]) - this.timeDifference + (this.adjustment + this.globalAdjustment + this.defaultAdjustment) * 1000) + ". Not playing...");
						// }
					}
					else{
						if(skipUpdatingShowCheck) {
							setTimeout(() => {
								console.log("received '" + status[0] + "' at " + status[1] + " Playing in " + playTime + " microseconds");
								this.wave?.play()
							}, (-1) * playTime);
						}
						this.show.status = "stop";
					}
				}
			}
			else{
				if(this.countdown > 0){
					console.log("received '" +  status[0] + "' countdown not finished. skipping...");
				}
				else{
					console.log("received '" +  status[0] + "' already playing. skipping...");
				}
			}
		}
		else if(status[0] == 'pause'){
			this.played = true;
			if(!this.interacting){
				this.wave?.seekTo(((status[2] - this.timeDifference) / 1000 + this.adjustment + this.globalAdjustment + this.defaultAdjustment) / this.audioLength);
				// if(this.wave){
				// 	console.log("Pause AT: "+((Number(status[2]) - this.timeDifference) / 1000));
				// 	this.wave.setTime((Number(status[2]) - this.timeDifference) / 1000);
				// }
			}
			if(this.wave?.isPlaying()) {
				console.log("received '" + status[0] + "' at " + status[2] + ". Paused...");
				this.wave?.pause()
			}
			else{
				console.log("received '" + status[0] + "' at " + status[2] + " already paused. Skipping...");
			}
		}
		else{
			if(this.wave?.getCurrentTime() && !this.interacting){
				console.log("received '" +  status[0] + "'. Stopped...");
				this.wave?.stop()
			}
			else{
				console.log("received '" +  status[0] + "' already stopped. Skipping...");
			}
		}
	}
	ngOnDestroy() {
		//this.subscription.unsubscribe();
		//this.firebaseSubscription.unsubscribe();
		if(this.secondsCounterTimeout){
			clearTimeout(this.secondsCounterTimeout);
		}
		clearTimeout(this.secondsCounterTimeout);
	}
  loadFileInfo() {
		if(this.show.audio_file_id && this.show.audio_file_name){
			this.showService.getFileInfo(this.show.audio_file_id, this.show).subscribe((response:any) => {
				this.fileInfo = response;
				this.audioLength = Number(this.fileInfo.file_duration)
				if(this.fileChanged){
					this.fileChanged = false;
					this.showService.updateAudioFile(this.show.show_id, this.show.audio_file_id).subscribe(response => {
						if(this.show.timecode_channel === 'right'){
							this.onProcessSMPTE.emit(this.show);
						}
						else{
							this.previewStart();
						}
					});
				}
				else{
					this.previewStart();
				}
			}, () => {
				if(this.retried <= 6){
					this.retried++;
					setTimeout(() => {
						this.loadFileInfo();
					}, 5000)
				}
				else{
					this.loadingError = true;
				}
			})
		}
	}
	generateWaveform(): void {
		Promise.resolve(null).then(() => {
			this.wave = WaveSurfer.create({
				container: '#waveform',
				waveColor: this.isTune2 ? '#DE072A' : (!this.isPyrocast ? '#0f57c6' : '#2cbcf4'),
				progressColor: this.isTune2 ? '#830008' : (!this.isPyrocast ? '#03238a' : '#03238a'),
				interact: true,
				plugins: [
					TimelinePlugin.create({
						container: '#wave-timeline'
					})
				]
			});
			this.wave.on('loading', (progress) => {
				this.progress = progress
			});
			this.wave.on('ready', () => {
				if (this.wave) {
					this.loaded = true;
					this.cdr.detectChanges();
				}
			});
			this.wave.on('play', () => {
				this.played = true;
				if(this.interactionAllowed){
					this.interactionAllowed = false;
					this.interacting = false;
					this.wave?.toggleInteraction(false);
				}
			});
			this.wave.on('pause', () => {
				if(!this.interactionAllowed){
					this.interactionAllowed = true;
					this.interacting = false;
					this.wave?.toggleInteraction(true);
				}
			});
			this.wave.on('finish', () => {
				if(this.show && !this.show.looping){
					if(!this.interactionAllowed){
						this.interactionAllowed = true;
						this.interacting = false;
						this.wave?.toggleInteraction(true);
					}
					this.updateShowStatus('stop');
				}
				else{
					this.wave?.play();
				}
			});
			this.wave.on('audioprocess', () => {
				this.updateTimer();
			})
			this.wave.on('seeking', () => {
				this.updateTimer();
			})
			this.wave.on('interaction', () => {
				this.interacting = true;
				return;
				// if(this.wave){
				// 	if(this.wave.isPlaying()){
				// 		this.wave.pause();
				// 	}
				// 	setTimeout(() => {
				// 		if(this.wave) {
				// 			console.log(this.wave.getCurrentTime() * 1000);
				// 			let btnPressTime = (+ new Date()) - this.timeDifference;
				// 			if(this.timeDifference < 0) {
				// 				btnPressTime -= this.timeDifference;
				// 			}
				// 			let seekTime = (this.wave.getCurrentTime() - (this.adjustment + this.globalAdjustment + this.defaultAdjustment)) * 1000;
				// 			if(seekTime < 0){
				// 				seekTime = 0;
				// 			}
				// 			this.updatingShow = true;
				// 			this.showService.updateShowJson(this.show, this.show.status + ',' + btnPressTime + ',' + seekTime + ',' + this.show.show_code + ',{timestamp},PyrocastWebsite: ' + this.datepipe.transform(new Date(), 'HH:mm:ss')).subscribe(() => {
				// 				this.timeout = setTimeout(() => {
				// 					this.updatingShow = false;
				// 				}, 5000)
				// 			});
				// 		}
				// 	}, 200);
				//}
			})
		});
	}

	updateTimer() {
  	if(this.wave){
			this.seekTime = new Date(this.wave.getCurrentTime()*1000 + this.timeOffset);
			this.cdr.detectChanges();
		}
	}

	previewStart(): void {
		if (!this.wave) {
			this.generateWaveform();
		}

		this.cdr.detectChanges();

		Promise.resolve().then(() => {
			if(this.wave) {
				this.wave.load((this.fileInfo.timecode_channel === 'right' ? this.fileInfo.timecode_file_url : this.fileInfo.file_url))//+'?v='+Math.random()
			}
		});
	}

	playConfirm() {
		this.audioStatusChangeConfirm(this.wave && this.wave.getCurrentTime() > 0 && this.played ? 'resume':'play');
	}

	pauseConfirm() {
		this.audioStatusChangeConfirm('pause');
	}

	stopConfirm() {
		this.audioStatusChangeConfirm('stop');
	}
	countdownStart(){
		this.countdownTimer = setTimeout(() => {
			this.countdown--;
			if(this.countdown > 0){
				this.countdownStart();
			}
			else{
				//this.wave?.play();
				//console.log(new Date())
			}
		}, 1000);
	}
	audioStatusChangeConfirm(status: string) {
		if(this.updatingShow){ return; }
		const dialogRef = this.dialog.open(ConfirmPopupComponent,{
			id: 'confirm-popup',
			maxWidth: '600px',
			width: '100%',
			data: {
				title: 'Confirm',
				message: 'Are you sure you want to '+ status +' the show?'
			}
		});
		dialogRef.componentInstance.onConfirm.subscribe(() => {
			this.updateShowStatus(status);
			dialogRef.close();
		})
	}

	cancelStart(){
		clearTimeout(this.countdownTimer);
		this.countdown = 0;
		this.updateShowStatus('stop');
	}

	updateShowStatus(status: any){
		this.show.status = status;
		let btnPressTime = 0;
		let seekTime = 0;
		if(this.show.status !== 'stop'){
			btnPressTime = (+ new Date()) - this.timeDifference;
			if(this.show.status === 'play'){
				// btnPressTime += 10000;
				// if(this.timeDifference < 0){
				// 	//btnPressTime -= this.timeDifference;
				// 	setTimeout(() => {
				// 		this.countdown = 10;
				// 		this.countdownStart();
				// 	}, Math.abs(this.timeDifference))
				// }
				// else{
				// 	this.countdown = 10;
				// 	this.countdownStart();
				// }
				this.countdown = 0;
				this.countdownStart();
				if(this.wave && this.wave.getCurrentTime()) {
					this.show.status = 'resume';
					seekTime = (this.wave.getCurrentTime() - (this.adjustment + this.globalAdjustment + this.defaultAdjustment)) * 1000;
				}
			}
			else if(this.wave){
				seekTime = (this.wave.getCurrentTime() - (this.adjustment + this.globalAdjustment + this.defaultAdjustment)) * 1000;
				if(seekTime < 0){
					seekTime = 0;
				}
			}
		}
		this.updatingShow = true;
		this.updatingShowCF = true;
		let data = this.show.status + ','+ btnPressTime +','+ seekTime +','+ this.show.show_code +',{timestamp},PyrocastWebsite: ' + this.datepipe.transform(new Date(), 'HH:mm:ss');
		if(this.isTune2){
			data = data + "," + (this.show.looping ? "true" : "false")
		}
		else if(this.show.looping){
			data = data + ",true"
		}
		const ts2 = (+ new Date()) - this.timeDifference;
		const data2 = data.replace('{timestamp}', (ts2) + '')
		const data3 = data.replace('{timestamp}', (ts2+1) + '')
		this.db.setShowStatus(this.show.show_code, data2);
		this.showService.updateShowJson(this.show, data3).subscribe(() => {
			if(this.timeout){
				clearTimeout(this.timeout);
			}
			this.updatingShow = false;
			this.timeout = setTimeout(() => {
				this.updatingShowCF = false;
			}, 5000)
		});
		if(this.show.status === 'stop'){
			this.played = false;
			this.wave?.stop()
		}
		else if(this.show.status === 'pause'){
			this.wave?.pause()
		}
		else if(this.show.status === 'resume' && this.played){
			this.wave?.play()
		}
		else if(this.show.status === 'play'){
			this.wave?.play();
		}
	}

	editAudioFile() {
		this.newAudioFile = false;
		const dialogRef = this.dialog.open(FileUploadPopupComponent, {
			id: 'file-upload',
			maxWidth: '600px',
			width: '100%',
			data: {
				title: 'Audio File',
				value: null
			}
		});
		dialogRef.afterClosed().subscribe(newValue => {
			if(newValue !== 'cancelDialog' ){
				this.show.audio_file_id = newValue.audio_file_id;
				this.show.audio_file_name = newValue.audio_file_name;
				if(this.show.audio_file_id){
					this.newAudioFile = true;
					this.seekTime = new Date(this.timeOffset);
					this.wave?.stop()
					this.loaded = false;
					this.progress = 0;
					this.retried = 0;
					this.loadingError = false;
					this.showService.updateShowJson(this.show, this.show.status + ',0,0,'+ this.show.show_code +',{timestamp},PyrocastWebsite: ' + this.datepipe.transform(new Date(), 'HH:mm:ss')).subscribe(() => {});
					this.fileChanged = true;
					this.loadFileInfo();
				}
			}
			else{

			}
		})
	}

	close() {
		// if (this.wave) {
		// 	this.wave.stop();
		// }
		this.dialogRef.close(this.show);
	}

	adjust(add: boolean){
		this.adjustment += (add ? 1 : -1) * 0.1;
		if(this.wave && this.wave.getCurrentTime() > 0){
			this.wave.skip((add ? 1 : -1) * 0.1);
		}
	}

	clear(){
		this.announcementComment.setValue('');
		this.processing = true;
		this.db.setData(this.show.show_code, {
			messageDateTime: new Date(),
			messageText: ''
		}).then(response => {
			this.processing = false;
		});
	}

	send(){
		this.processing = true;
		this.db.setData(this.show.show_code, {
			messageDateTime: new Date(),
			messageText: this.announcementComment.value
		}).then(response => {
			this.processing = false;
		});
	}

	setTimeout() {
		this.userActivity = setTimeout(() => {
			if(!this.wave?.isPlaying()) {
				this.userInactive.next(undefined)
			}
		}, 3600000);
	}

	@HostListener('window:mousemove')
	@HostListener('window:keydown')
	@HostListener('window:wheel')
	@HostListener('window:touchstart') refreshUserState() {
		if(!this.confirmingClose) {
			clearTimeout(this.userActivity);
			this.setTimeout();
		}
	}
}
