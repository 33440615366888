<h2 class="popup-heading" mat-dialog-title>API Key</h2>
<mat-dialog-content class="mat-typography">
	<div class="brands-view">
		<div class="music-box settings-box">
			<p style="text-align: left">Some 3rd party applications may require an API key. Please find your unique API key below.</p>
            <h2>API Key:
                <span>
                    {{ isRevealed ? api_key : '********-****-****-****-************' }}
                    <button mat-icon-button class="add-btn" (click)="copyApiKey()" *ngIf="isRevealed">
                        <mat-icon>file_copy</mat-icon>
                    </button>
                </span>
            </h2>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions style="padding: 15px;" align="end">
	<button mat-raised-button 
            [ngStyle]="{'background-color': 'var(--accent-color)', 'color': 'var(--light-color)'}" 
            class="add-btn" 
            (click)="revealApiKey()">
        <span style="line-height: 24px;margin-right: 5px;display: inline-block;">Reveal API Key</span>
    </button>
    <button mat-raised-button 
            [ngStyle]="{'background-color': 'var(--accent-color)', 'color': 'var(--light-color)'}" 
            class="add-btn" 
            (click)="generateApiKey()">
        <span style="line-height: 24px;margin-right: 5px;display: inline-block;">Revoke and Generate New</span>
    </button>
</mat-dialog-actions>
