<mat-dialog-content class="mat-typography">
	<div>
		<p>The following QR code is the 4-digit show code: {{ show.show_code }}. After installing the app, the user can scan this code using from the app main menu.</p>
		<img [src]="this.qrcodeUrl" style="width: 120px; display: block; margin:0 auto;" />
	</div>
</mat-dialog-content>
<br>
<mat-dialog-actions align="end">
	<a [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" mat-button [href]="this.qrcodeUrl" download>Download QRCode</a>
	<button class="cancel-popup" mat-stroked-button (click)="cancel()">Close</button>
</mat-dialog-actions>
