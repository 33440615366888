<div class="content">
	<mat-card appearance="outlined" class="example-card">
		<mat-card-content>
			<p>Brands allow you to customize the look and feel of the experience after the show code is entered. This
				includes a header and footer image, background and colors, and more... By editing a brand, you can access
				these customizations. Once created, you can assign the brand to a show by editing the show and selecting the
				brand. By accessing the show from the app, you can preview how it looks for the end user.</p>
		</mat-card-content>
	</mat-card>
	<div class="my-show-table">
		<div class="btn">
			<mat-form-field class="table-search">
				<mat-label>Search brands...</mat-label>
				<input matInput placeholder="Enter search term here" #search [(ngModel)]="s">
			</mat-form-field>
			<div class="shows-btn">
				<!--<button mat-raised-button color="accent" class="add-btn" (click)="mySingleShow()"><span style="line-height: 24px;margin-right: 5px;display: inline-block;">Single Show</span> </button>-->
				<button mat-raised-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" class="add-btn" (click)="add()"><span
						style="line-height: 24px;margin-right: 5px;display: inline-block;">CREATE NEW BRAND</span>
					<mat-icon>add_box</mat-icon>
				</button>
			</div>
		</div>

		<div class="mat-elevation-z8">
			<table mat-table [dataSource]="dataSource" matSort matSortActive="created_date" matSortDirection="desc">

				<!-- Position Column -->
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header class="show-sm" width="73%"> Name </th>
					<td mat-cell *matCellDef="let element" (click)="edit(element)" class="show-sm"> {{element.name}} </td>
				</ng-container>

				<ng-container matColumnDef="created_at">
					<th mat-header-cell *matHeaderCellDef mat-sort-header width="14%"> Created Date </th>
					<td mat-cell *matCellDef="let element"> {{element.created_at|date}} </td>
				</ng-container>

				<ng-container matColumnDef="action">
					<th mat-header-cell *matHeaderCellDef class="show-sm action-col" width="7%"> Action </th>
					<td mat-cell *matCellDef="let element" class="show-sm action-col">
						<div class="table-select">
							<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Brand Actions"
								[disabled]="element.processing">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item (click)="editName(element)">
									<span>Edit Name</span>
								</button>
								<button mat-menu-item (click)="edit(element)">
									<span>Edit</span>
								</button>
								<button mat-menu-item (click)="delete(element.id)">
									<span>Delete</span>
								</button>
							</mat-menu>
						</div>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
				<tr class="mat-row" *matNoDataRow>
					<td colspan="7" class="mat-cell show-sm">{{ loading ? 'Loading...':'No records found.'}}</td>
				</tr>
			</table>
			<mat-paginator [class]="total_brands <= 10 ? 'hidden_dropdown':''" [pageSizeOptions]="[10, 50, 100, 250, 500]"
				aria-label="Select page of the records."></mat-paginator>
		</div>
	</div>
</div>
