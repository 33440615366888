import { Component } from '@angular/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.scss'
})
export class PricingComponent {
  displayedColumns: string[] = ['range', 'amount'];
  pricingData = [
    { range: '0 to 10', amount: '$49.99' },
    { range: '10 to 50', amount: '$129.99' },
    { range: '50 to 250', amount: '$249.00' },
    { range: '250 to 500', amount: '$399.00' },
    { range: '500 to 1000', amount: '$999.00' },
    { range: '1000 to 5000', amount: '$1,999.00' },
    { range: '5000 to 15000', amount: '$2,999.00' },
    { range: '15000 to 50000', amount: '$3,999.00' },
    { range: '50000+', amount: '$4,999.00' }
  ];
}
