import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {BrandsService} from "../../services/brands.service";
import {Brand} from "../../interfaces/brand.interface";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-edit-brand-name',
  templateUrl: './edit-brand-name.component.html',
  styleUrls: ['./edit-brand-name.component.scss']
})
export class EditBrandNameComponent implements OnInit {

	processing = false;
	brandForm: any;
	brand: any;
	constructor(private dialogRef: MatDialogRef<EditBrandNameComponent>,
							private fb: UntypedFormBuilder,
							public brandService: BrandsService,
							@Inject(MAT_DIALOG_DATA) data: any,
							public _snackBar: MatSnackBar) {
		this.brand = data.brand;
		this.brandForm = this.fb.group({
			name: [this.brand.name, Validators.required],
		});
	}

	ngOnInit(): void {
	}

	save(){
		if(!this.processing && this.brandForm.valid){
			const brand = this.brandForm.value as Brand;
			brand.id = this.brand.id;
			this.processing = true;
			this.brandService.edit(brand)
				.subscribe((res: any) => {
					if(res.status){ this.processing = false; this.dialogRef.close(res.brand); this._snackBar.open('Brand Name updated successfully.', 'Dismiss'); }
				});
		}
	}

}
