<div class="login">
    <div class="login-area">
        <div class="login-heading">
            <img src="../../assets/img/skyjams.png" />
        </div>
        <div class="login-text">
            <p>Have your audience listen to the fireworks show soundtrack in perfect sync directly from their phone.</p>
            <p>Skyjams handles everything from firing system integration, to perfect audio sync, and music licensing.</p>
        </div>
        <div class="form">
            <div class="form-field-first">
                <mat-form-field appearance="outline" class="half-width-field">
                    <mat-label>First Name</mat-label>
                    <input matInput placeholder="First Name">
                </mat-form-field>
                <mat-form-field appearance="outline" class="half-width-field">
                    <mat-label>Last Name</mat-label>
                    <input matInput placeholder="Last Name">
                </mat-form-field>
            </div>
            <div class="form-field-second">
                <mat-form-field appearance="outline" class="full-width-field">
                    <mat-label>Email Address</mat-label>
                    <input matInput placeholder="Email Address">
                </mat-form-field>
                <mat-form-field appearance="outline" class="full-width-field">
                    <mat-label>Comment</mat-label>
                    <input matInput placeholder="Comment">
                </mat-form-field>
            </div>
            <div class="form-field-last">
                <button mat-raised-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}">Sign up for the BETA program</button>
            </div>
        </div>
    </div>
</div>

