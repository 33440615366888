<h2 class="popup-heading" mat-dialog-title>{{ title }}</h2>
<mat-dialog-content class="mat-typography">
    <div class="brands-view">
        <p>{{ message }}</p>
				<span class="timer">{{ time }} seconds</span>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="cancel-popup" mat-stroked-button (click)="cancel()">Close Now</button>
  <button class="popup-save" mat-flat-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" (click)="confirm()" cdkFocusInitial>Continue Listening</button>
</mat-dialog-actions>
