import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import { UntypedFormControl, Validators } from '@angular/forms';
import {Show} from "../../interfaces/show.interface";
import {ShowsService} from "../../services/shows.service";
import {DatabaseService} from "../../services/database.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrl: './announcements.component.scss'
})
export class AnnouncementsComponent {
	public title = '';
	announcementComment = new UntypedFormControl('', Validators.required);
	public processing = false;
	public show:Show;
	public loaded = false;
	public isPyrocast = true;
	public siteName = 'Pyrocast';
	public isTune2 = false;

  constructor(private dialogRef: MatDialogRef<AnnouncementsComponent>,
    @Inject(MAT_DIALOG_DATA) data:any,
    private showService: ShowsService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private db: DatabaseService) {
      this.title = data.title;
      if(window.location.hostname == environment.skyDomain){
        this.isPyrocast = false;
        this.siteName = 'SkyStreamEvents'
      }
      if(window.location.hostname == environment.tune2Domain || window.location.hostname == environment.tune2Domain2){
        this.isPyrocast = false;
        this.isTune2 = true;
        this.siteName = 'Tune2'
      }
      this.show = data.show;
      this.db.getData( this.show.show_code ).subscribe((response:any) => {
        if(response && response.messageText) {
          this.announcementComment.setValue(response.messageText);
        }
      })
    }
    clear(){
      this.announcementComment.setValue('');
      this.processing = true;
      this.db.setData(this.show.show_code, {
        messageDateTime: new Date(),
        messageText: ''
      }).then(response => {
        this.processing = false;
      });
    }
    close() {
      this.dialogRef.close(this.show);
    }
    send(){
      this.processing = true;
      this.db.setData(this.show.show_code, {
        messageDateTime: new Date(),
        messageText: this.announcementComment.value
      }).then(response => {
        this.processing = false;
      });
    }
}
