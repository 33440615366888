import {Component, ElementRef, ViewChild, ViewEncapsulation} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, shareReplay } from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from "../services/auth/auth.service";
import {MatDrawer} from "@angular/material/sidenav";
import {FileUploadPopupComponent} from "../common/file-upload-popup/file-upload-popup.component";
import {SettingsComponent} from "../settings/settings.component";
import {ApiKeyComponent} from "../api-key/api-key.component";
import {environment} from "../../environments/environment";
import {MatSnackBar} from "@angular/material/snack-bar";


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  fullPageTemplate = false;
  env: any;
	isAdmin = false;
	token: any;
	isLoading = false;
	logo = '';
	isPyrocast = true;
	isTune2 = false;
	@ViewChild('drawer') drawer!: MatDrawer;
  constructor(
    private authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
		public _snackBar: MatSnackBar) {
  	this.env = environment;
  	this.authService.isAdmin.subscribe((status: any) => {
  		this.isAdmin = status;
		});
		this.authService.token.subscribe((token: any) => {
			this.token = token;
			console.log(token);
		});
		if(window.location.hostname == environment.skyDomain){
			this.logo = 'skystream_logo.png';
			this.isPyrocast = false;
		}
		else if(window.location.hostname == environment.tune2Domain || window.location.hostname == environment.tune2Domain2){
			this.logo = 'tune2-logo.webp';
			this.isPyrocast = false;
			this.isTune2 = true;
		}
		else{
			this.logo = 'pyrocast-logo.webp';
		}
		this.authService.refreshToken();
  }

	settings() {
		const dialogRef = this.dialog.open(SettingsComponent, {
			id: 'settings',
			maxWidth: '600px',
			width: '100%',
		});
		dialogRef.afterClosed().subscribe(newValue => {})
	}
	apiKey(){
		const dialogRef = this.dialog.open(ApiKeyComponent, {
			id: 'api-key',
			maxWidth: '600px',
			width: '100%',
		});
		dialogRef.afterClosed().subscribe(newValue => {})
	}

  closeDrawer() {
		this.isHandset$.subscribe(response => {
			if(response){
				this.drawer.close();
			}
		});
	}

   linkedUserLogin(email: string){
  	this.isLoading = true;
		this.authService.linkedUserLogin(email).subscribe((response) => {
			this.isLoading = false;
			localStorage.setItem('token', response.token);
			window.location.href = 'home';
		}, (error: any) => {
			this.isLoading = false;
			this._snackBar.open(error.error.error, 'Dismiss');
		});
	}

	collaboratorLogin(email: string){
		this.isLoading = true;
		  this.authService.collaboratorLogin(email).subscribe((response) => {
			  this.isLoading = false;
			  localStorage.setItem('token', response.token);
			  window.location.href = 'home';
		  }, (error: any) => {
			  this.isLoading = false;
			  this._snackBar.open(error.error.error, 'Dismiss');
		  });
	  }

  logout(event:any) {
    this.authService.logout().then(() => {
    	window.location.href = '/login';
    });
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(data => {
        this.fullPageTemplate = data['fullPageTemplate']
      }
    )
  }

}
