import { Component } from '@angular/core';

@Component({
  selector: 'app-audio-message-popup',
  templateUrl: './audio-message-popup.component.html',
  styleUrl: './audio-message-popup.component.scss'
})
export class AudioMessagePopupComponent {

}
