<div class="login" *ngIf="!brandFetched">
	<div style="padding: 20px;">
		Please wait...
		<mat-progress-bar mode="indeterminate"></mat-progress-bar>
	</div>
</div>
<div *ngIf="brandFetched" class="edit-brand" [ngStyle]="{'background-color': brand?.background_color ?? '' }">
	<div class="edit-header">
		<div style="height: 120px;">
			<img [src]="versionedImage(brand?.header_image)" *ngIf="brand?.header_image" />
		</div>
	</div>
	<div class="edit-content">
		<h3> <b [ngStyle]="{'color': brand?.heading_color ?? '', 'font-family': font.family}"> {{ show.custom_name }} </b>
			<span [ngStyle]="{'color': brand?.text_color ?? '', 'font-family': font.family }"> {{ show.website_url }} </span>
		</h3>
		<span [ngStyle]="{'color': brand?.text_color ?? '', 'font-family': font.family }"> {{ show.address1 }} </span>
		<span [ngStyle]="{'color': brand?.text_color ?? '', 'font-family': font.family }"> {{ show.address2 }} </span>
		<span style="margin-bottom: 20px;" [ngStyle]="{'color': brand?.text_color ?? '', 'font-family': font.family }"> {{ show.city }}{{(show.city ? ', ' : '')}}{{show.state}}{{(show.state ? ', ' : '') }}{{show.country }}</span>

		<h3 [ngStyle]="{'color': brand?.heading_color ?? '', 'font-family': font.family }"><span *ngIf="show.show_date">{{ show.show_date | date: 'MMMM d, y' }}{{show.show_time ? ', at ' + show.show_time : ''}} {{ show.show_timezone }}</span></h3>
		<p [ngStyle]="{'color': brand?.text_color ?? '', 'font-family': font.family }">{{ show.description }}</p>

		<div class="event-button">
			<button mat-raised-button [ngStyle]="{'background-color': brand?.button_color ?? '#2cbcf4', 'color': brand?.button_text_color ?? '#ffffff', 'font-family': font.family}">Listen to Show</button>
		</div>
		<div class="event-button">
			<button mat-raised-button [ngStyle]="{'background-color': brand?.button_color ?? '#2cbcf4', 'color': brand?.button_text_color ?? '#ffffff', 'font-family': font.family}">Map Directions</button>
		</div>
		<div class="event-button">
			<button mat-raised-button [ngStyle]="{'background-color': brand?.button_color ?? '#2cbcf4', 'color': brand?.button_text_color ?? '#ffffff', 'font-family': font.family}">Back to Menu</button>
		</div>
	</div>
	<div class="edit-footer">
		<div style="height: 90px;">
			<img [src]="versionedImage(brand?.footer_image)" *ngIf="brand?.footer_image" />
		</div>
	</div>
</div>
