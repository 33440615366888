<h2 class="popup-heading" mat-dialog-title>Create New Show</h2>
<form>
	<mat-dialog-content class="mat-typography">
		<div class="row">
			<div class="col-md-12">
				<mat-form-field appearance="fill">
					<mat-label>Show Name</mat-label>
					<input matInput placeholder="Enter Show Name" [formControl]="showForm.controls.custom_name" required>
					<mat-error *ngIf="showForm.controls.custom_name.hasError('required')">
						Show Name is <strong>required</strong>
					</mat-error>
				</mat-form-field>
				<mat-form-field appearance="fill" [class]="!env.showBrands ? 'hidden' : ''">
					<mat-label>Brand</mat-label>
					<mat-select disableRipple [formControl]="showForm.controls.brand_id">
						<mat-option [value]="0">Select Brand</mat-option>
						<mat-option *ngFor="let brand of brands" [value]="brand.id">{{ brand.name }}</mat-option>
					</mat-select>
				</mat-form-field>
				<p class="text-align">Brands allow you to customize the look and feel of the experience after the show code is
					entered. This
					includes a header and footer image, background and colors, and more... To learn more and to create / edit
					brands, goto My Brands in the menu.</p>
				<mat-card appearance="outlined" class="custom-card" style="margin-bottom: 20px;">
					<mat-card-header>
						<mat-card-title>Venue Location</mat-card-title>
						<p>This is the address of the physical venue where your show will take place.</p>
					</mat-card-header>
					<mat-card-content>
						<mat-form-field appearance="fill">
							<mat-label>Address Line 1</mat-label>
							<textarea matInput [formControl]="showForm.controls.address1"></textarea>
						</mat-form-field>
						<mat-form-field appearance="fill" class="single-field">
							<mat-label>Address Line 2</mat-label>
							<textarea matInput [formControl]="showForm.controls.address2"></textarea>
						</mat-form-field>
						<mat-form-field appearance="fill" class="single-field">
							<mat-label>City</mat-label>
							<input matInput [formControl]="showForm.controls.city">
						</mat-form-field>
						<mat-form-field appearance="fill" class="single-field">
							<mat-label>State</mat-label>
							<input matInput [formControl]="showForm.controls.state">
						</mat-form-field>
						<mat-form-field appearance="fill" class="single-field">
							<mat-label>Country</mat-label>
							<input matInput [formControl]="showForm.controls.country">
						</mat-form-field>
					</mat-card-content>
				</mat-card>


				<mat-form-field appearance="fill">
					<mat-label>Choose Show date</mat-label>
					<input matInput [matDatepicker]="picker" [formControl]="showForm.controls.show_date">
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-datepicker #picker></mat-datepicker>
				</mat-form-field>
				<!-- <mat-form-field>
					<input matInput type="time" placeholder="Choose Show Time" [formControl]="showForm.controls.show_time">
				  </mat-form-field> -->
				<div class="show-time" appearance="fill">
					<h4 class="text">Show Time</h4>
					<mat-form-field class="half-width">
						<mat-label>Hours</mat-label>
						<mat-select [formControl]="showForm.controls.show_hours">
							<mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }}</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field class="half-width">
						<mat-label>Minutes</mat-label>
						<mat-select [formControl]="showForm.controls.show_minutes">
							<mat-option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<mat-form-field appearance="fill">
					<mat-label>Timezone</mat-label>
					<mat-select disableRipple [formControl]="showForm.controls.show_timezone">
						<mat-option [value]="">Select Timezone</mat-option>
						<mat-option *ngFor="let timezone of timezones" [value]="timezone.diff">
						  <span class="timezone-option" [title]="timezone.diff">
							{{ timezone.name + " (UTC " + timezone.diff + ")" }}
						  </span>
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="fill">
					<mat-label>Date and Time Override Text</mat-label>
					<input matInput placeholder="Enter Date and Time Override Text" [(ngModel)]="showForm.controls.datetime_text">
				</mat-form-field>
				<p class="text-align">Replace the date and time on the app and website show page with this text. By populating this field, you won't affect any change to functionality associated to the show's official show date and show time.</p>
				<h3 *ngIf="isTune2" class="custom-heading">Static Show</h3>
				<div>
					<mat-checkbox class="add-smpte-chkbx" [formControl]="showForm.controls.start_automatically">Start show automatically at this time
					</mat-checkbox>
					<small style="display: block; margin-left: 38px;">By selecting the option, the show will start automatically at this time based on the date, time and selected time zone. We suggest you test this functionality based on your current time zone as a dry run to ensure everything is set correctly.</small><br>
				</div>
				<div>
					<mat-checkbox class="add-smpte-chkbx"  [formControl]="showForm.controls.start_from_beginning">Start show automatically from beginning</mat-checkbox>
					<small style="display: block; margin-left: 38px;">The show will start automatically from the beginning of the show. This means listeners will listen at different times depending on when they opened the show. This is helpful for shows with announcements or other content you want to make sure they hear.</small><br>
				</div>
				<div>
					<mat-checkbox class="add-smpte-chkbx" [formControl]="showForm.controls.looping">Loop Soundtrack
					</mat-checkbox>
					<small style="display: block; margin-left: 38px;">By looping the soundtrack, the soundtrack will continue to play back to back for the listener. It is recommended to use this feature in combination with the start automatically feature.</small><br>
				</div>
				<div class="col-md-12">
					<input type="file" class="file-input" [accept]="requiredFileType" (change)="onFileSelected($event)" #fileUpload
						style="display: none">
	
					<div class="file-upload">
						<button mat-raised-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" class="upload-btn" (click)="fileUpload.click()">
							<!--<mat-icon>attach_file</mat-icon>-->
							{{fileName || "Browse and select MP3 file..."}}
						</button>
					</div>
	
					<div class="progress">
						<mat-progress-bar class="progress-bar" mode="determinate" [value]="uploadProgress" *ngIf="uploadProgress">
						</mat-progress-bar>
						<button *ngIf="uploadProgress" mat-mini-fab [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" class="cancel-upload" (click)="cancelUpload()">
							<mat-icon>delete_forever</mat-icon>
						</button>
					</div>
				</div>
				<div *ngIf="isTune2" style="margin-bottom: 20px;">
					<h3 class="custom-heading">Sequenced Show</h3>
					<p class="text-align">If you are performing a synchronized / sequenced lights show using xLights (xSchedule), Falcon Player (FPP), and Light-O-Rama (LOR), you don't need to upload a soundtrack as this is done automatically using the Tune2 Sync application after you create the show from here.</p>
					<button mat-raised-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" class="instruction-button" (click)="openTune2Sync()">
						Tune2 Sync Installation Instructions
					</button>
				</div>
				<mat-form-field appearance="fill" class="single-field">
					<mat-label>Description</mat-label>
					<textarea matInput [formControl]="showForm.controls.description" rows="10"></textarea>
				</mat-form-field>
				<mat-form-field appearance="fill" class="single-field">
					<mat-label>Website URL (make sure to include http:// or https://)</mat-label>
					<input matInput [formControl]="showForm.controls.website_url">
				</mat-form-field>
				<mat-form-field appearance="fill" class="single-field">
					<mat-label>Extra Button Label (up to 15 characters)</mat-label>
					<input type="text" matInput [formControl]="showForm.controls.extra_button_text" maxlength="15">
				</mat-form-field>
				
				<mat-form-field appearance="fill" class="single-field">
					<mat-label>Extra Button URL (make sure to include http:// or https://)</mat-label>
					<input type="text" matInput [formControl]="showForm.controls.extra_button_url">
				</mat-form-field>
				
				<div class="editor-field">
					<label>Terms & Conditions</label>
					<angular-editor [formControl]="showForm.controls.terms" [config]="editorConfig"></angular-editor>
				</div>
				<!--<mat-form-field appearance="fill">
					<mat-label>Video Before Show</mat-label>
					<input matInput [formControl]="showForm.controls.video_before_show">
				</mat-form-field>
				<mat-form-field appearance="fill">
					<mat-label>Video After Show</mat-label>
					<input matInput [formControl]="showForm.controls.video_after_show">
				</mat-form-field>-->
			</div>
		</div>
		<div>
			<mat-checkbox class="add-smpte-chkbx" [formControl]="showForm.controls.add_smpte">Add SMPTE (Right Channel)
				Starting at 00:00:00.0s
			</mat-checkbox>
			<small style="display: block; margin-left: 38px;">To add SMPTE to the audio track, please choose this
				option. <b>Do not</b> add SMPTE to the MP3 soundtrack on your own prior to uploading to {{siteName}}.
				Instead, use this option to ensure the SMPTE is properly layered.</small>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button class="cancel-popup" mat-stroked-button mat-dialog-close>Cancel</button>
		<button class="popup-save" mat-flat-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" type="submit" (click)="process()" [disabled]="processing">
			<mat-icon *ngIf="processing">
				<mat-spinner class="accent-spinner" diameter="20"></mat-spinner>
			</mat-icon>
			Save
		</button>
	</mat-dialog-actions>
</form>
