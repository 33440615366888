<div class="content">
    <h2 class="mat-dialog-title popup-heading">Billing Information</h2>
    <form class="billing-form">
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>First Name</mat-label>
            <input matInput placeholder="Enter first name" required />
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Last Name</mat-label>
            <input matInput placeholder="Enter last name" required />
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Address Line 1</mat-label>
            <input matInput placeholder="Enter address" required />
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Address Line 2</mat-label>
            <input matInput placeholder="Enter address (optional)" />
        </mat-form-field>
        
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>City</mat-label>
            <input matInput placeholder="Enter city" required />
        </mat-form-field>

        <div class="half-width">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>State</mat-label>
                <input matInput placeholder="Enter state" required />
            </mat-form-field>
        
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Zip / Postal Code</mat-label>
                <input matInput placeholder="Enter zip code" required />
            </mat-form-field>
        </div>

        <div class="action-buttons" align="end">
            <button class="save" mat-flat-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" type="submit" [disabled]="processing">
                <mat-icon *ngIf="processing">
                    <mat-spinner class="accent-spinner" diameter="20"></mat-spinner>
                </mat-icon>
                Save
            </button>
        </div>
    </form>
</div>  