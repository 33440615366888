<div class="content">
    <h2 class="mat-dialog-title popup-heading">Available on iOS and Android.</h2>
    <div class="content-text">
        <p>Please find links to the app below.</p>
        <p>For show management, please use <a [href]="'https://'+domain">{{ domain }}</a>. The app is only for starting and listening to your show, not creating shows. <span *ngIf="isPyrocast">For more details, please see our FAQs.</span></p>
        <p class="links" *ngIf="googlePlayLink">
            <a [href]="googlePlayLink" class="google-play" target="_blank"><img src="/assets/img/google-play.webp" /></a> <a [href]="appStoreLink" class="app-store" target="_blank"><img src="/assets/img/app-store.webp" /></a>
        </p>
        </div>
</div>
