<div class="content">
	<div class="my-show-table">
        <!-- <div class="finger-head">
                <mat-form-field appearance="standard">
                    <mat-label>From Date</mat-label>
                    <input matInput [matDatepicker]="picker1" [(ngModel)]="start_date">
                    <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                  
                  <mat-form-field appearance="standard">
                    <mat-label>To Date</mat-label>
                    <input matInput [matDatepicker]="picker2" [(ngModel)]="end_date">
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2 color="primary"></mat-datepicker>
                  </mat-form-field>
                  
                <button mat-raised-button color="accent" class="add-btn" (click)="getReport()"><span style="line-height: 24px;margin-right: 5px;display: inline-block;">SUBMIT</span></button>
        </div> -->
        <div class="btn" style="
        width: 100%;
        display: block;
        text-align: right;
        margin-bottom: 15px;">
                      <div class="shows-btn">
                          <!--<button mat-raised-button color="accent" class="add-btn" (click)="mySingleShow()"><span style="line-height: 24px;margin-right: 5px;display: inline-block;">Single Show</span> </button>-->
                          <button mat-raised-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" class="add-btn" (click)="add()"><span style="line-height: 24px;margin-right: 5px;display: inline-block;">Add New Notification</span> <mat-icon>add_box</mat-icon></button>
                      </div>
          </div>
		<div class="mat-elevation-z8 ex">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
              
                <!-- Position Column -->
                <ng-container matColumnDef="datetime">
                  <th mat-header-cell *matHeaderCellDef width="20%"> Date/Time </th>
                  <td mat-cell *matCellDef="let element"> {{element.datetime | date : 'medium'}} </td>
                </ng-container>
              
                <!-- Name Column -->
                <ng-container matColumnDef="version">
                  <th mat-header-cell *matHeaderCellDef width="10%"> App Version </th>
                  <td mat-cell *matCellDef="let element"> {{element.version}} </td>
                </ng-container>
                
                <!-- Name Column -->
                <ng-container matColumnDef="upgrade_message">
                  <th mat-header-cell *matHeaderCellDef width="5%" style="text-align: center"> Upgrade Message? </th>
                  <td mat-cell *matCellDef="let element" style="text-align: center"> {{element.upgrade_message ? 'Yes' : 'No'}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="critical">
                  <th mat-header-cell *matHeaderCellDef width="10%" style="text-align: center"> Critical? </th>
                  <td mat-cell *matCellDef="let element" style="text-align: center"> {{element.critical ? 'Yes' : 'No'}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="message">
                  <th mat-header-cell *matHeaderCellDef> Platform </th>
                  <td mat-cell *matCellDef="let element"> {{element.app}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef width="20%"> Title </th>
                  <td mat-cell *matCellDef="let element"> {{element.title}} </td>
                </ng-container>

                <ng-container matColumnDef="url">
                    <th mat-header-cell *matHeaderCellDef> Message (HTML) </th>
                    <td mat-cell *matCellDef="let element"> <div [innerHTML]="element.url"></div>
                    </td>
                  </ng-container>
                
                <!-- Weight Column -->
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef class="show-sm action-col" width="7%"> Action </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="table-select">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button (click)="delete(element)" mat-menu-item>
                                <span>Delete</span>
                            </button>
                        </mat-menu>
                    </div>
                  </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td colspan="8" class="mat-cell">{{ loading ? 'Loading...':'No records found.'}}</td>
                </tr>
              </table>
		</div>
	</div>
</div>