<h2 class="popup-heading" mat-dialog-title>{{ data.collaborator ? 'Edit' : 'Create New' }} Collaborator User</h2>
<form>
	<mat-dialog-content class="mat-typography">
		<div class="row">
			<div class="col-md-12">
				<mat-form-field appearance="fill">
					<mat-label>Email</mat-label>
							<input matInput placeholder="Enter Email" oninput="this.value = this.value.toLowerCase().replaceAll(' ', '')" [formControl]="collaboratorsForm.controls.email" required>
							<mat-error *ngIf="collaboratorsForm.controls.email.hasError('required')">
								Email is <strong>required</strong>
							</mat-error>
					</mat-form-field>
			</div>
			<!-- <div class="col-md-12">
				<mat-form-field appearance="fill">
					<mat-label>User Email</mat-label>
							<textarea matInput placeholder="Enter User Email" [formControl]="collaboratorsForm.controls.email" required></textarea>
							<mat-error *ngIf="collaboratorsForm.controls.email.hasError('required')">
								User Emails are <strong>required</strong>
							</mat-error>
					</mat-form-field>
			</div> -->
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button class="cancel-popup" mat-stroked-button mat-dialog-close>Cancel</button>
		<button class="popup-save" mat-flat-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" type="submit" (click)="save()" [disabled]="processing">
			<mat-icon *ngIf="processing">
				<mat-spinner class="accent-spinner" diameter="20"></mat-spinner>
			</mat-icon>
			Save
		</button>
	</mat-dialog-actions>
</form>
