<div class="login" *ngIf="processing">
	<div style="width: 80%">
		Please wait...
		<mat-progress-bar mode="indeterminate"></mat-progress-bar>
	</div>
</div>
<div class="login" *ngIf="!processing">
    <div class="login-area">
        <div class="login-heading">
					<img [src]="'assets/img/' + logo" style="max-width: 100%" />
        </div>
        <div class="login-text">
            <p *ngIf="isPyrocast">If you have an IGNITE Show Designer or COBRA Show Creator account, please login using the same email address to access shows for these platforms</p>
        </div>
        <div class="login-btn">
            <a (click)="google_login()">
                <img src="assets/img/new-google-login.png" />
                Sign in With Google
            </a>
            <a class="apple-icon" (click)="apple_login()">
                <img src="assets/img/new-apple-login.png" />
                Sign in With Apple
            </a>
        </div>
    </div>
</div>

