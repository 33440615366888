import { Component } from '@angular/core';
import { ShowsService } from '../services/shows.service';
import { MatDialog } from '@angular/material/dialog';
import { RevokeConfirmationComponent } from './revoke-confirmation/revoke-confirmation.component';

@Component({
  selector: 'app-api-key',
  templateUrl: './api-key.component.html',
  styleUrls: ['./api-key.component.scss']
})
export class ApiKeyComponent {
  api_key: string = '';
  isRevealed: boolean = false;
  apiKeyFetched: boolean = false;

  constructor(public showsService: ShowsService, private dialog: MatDialog) {}

  revealApiKey() {
    if (!this.apiKeyFetched) {
      this.showsService.getApiKey().subscribe((response: any) => {
        this.api_key = response.api_key;
        this.isRevealed = true;
        this.apiKeyFetched = true;
      });
    } else {
      this.isRevealed = true;
    }
  }

  generateApiKey() {
    const dialogRef = this.dialog.open(RevokeConfirmationComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showsService.generateApiKey().subscribe((response: any) => {
          this.api_key = response.api_key;
          this.isRevealed = true;
        });
      }
    });
  }

  copyApiKey() {
    const key = this.api_key;
    navigator.clipboard.writeText(key).then(
      () => {
        console.log('API key copied to clipboard');
      },
      err => {
        console.error('Could not copy text: ', err);
      }
    );
  }
}
