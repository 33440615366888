import { LinkedUser } from './../interfaces/linked-user.interface';
import { LinkedUsersService } from './../services/linked-users.service';
import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {fromEvent} from "rxjs";
import {debounceTime, distinctUntilChanged, tap} from "rxjs/operators";
import {AuthService} from "../services/auth/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {LoadingComponent} from "../common/loading/loading.component";
import { ConfirmPopupComponent } from '../common/confirm-popup/confirm-popup.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import {AddEditLinkedUsersComponent} from "./add-edit-linked-users/add-edit-linked-users.component";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-linked-users',
  templateUrl: './linked-users.component.html',
  styleUrls: ['./linked-users.component.scss']
})
export class LinkedUsersComponent implements OnInit {

	displayedColumns: string[] = ['admin_email', 'user_emails', 'action'];
	dataSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);
	s: string = '';
	total_linked_users = 0;
	status = [];
	loading = false;

	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort)sort!: MatSort;
	@ViewChild('search') search!: ElementRef;

	ngAfterViewInit() {
		this.paginator._intl.itemsPerPageLabel="Linked Users per page:";
		this.dataSource.sort = this.sort;
		this.dataSource.paginator = this.paginator;
		fromEvent(this.search.nativeElement,'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
				tap(() => {
					this.paginator.pageIndex = 0;
					this.updateFilters();
				})
			)
			.subscribe();
	}

	updateFilters() {
		this.dataSource.filter = JSON.stringify({ s: this.s });
	}

	linkedUsersFilter() {
		let filterFunction = function (data: any, filter: string): boolean {
			let filters = JSON.parse(filter);
			let match = true;
			return match && ( data.admin_email && data.admin_email.toString().toLowerCase().trim().indexOf(filters.s.toLowerCase()) !== -1 ||
				data.user_emails && data.user_emails.toString().toLowerCase().trim().indexOf(filters.s.toLowerCase()) !== -1);
		}
		return filterFunction
	}

	constructor(public linkedUsersService: LinkedUsersService,
							public auth: AuthService,
							public dialog: MatDialog,
							private cdr: ChangeDetectorRef,
							public _snackBar: MatSnackBar) {
		this.loadData();
	}
	loadData(newLinkedUser:any = null) {
		this.loading = true;
		this.dataSource.data = [];
		this.linkedUsersService.index({}, 'admin_email', 'desc', 1, 1000).subscribe(data => {
			this.loading = false;
			data.map( (linkedUser: LinkedUser) => {
				return linkedUser;
			})
			this.dataSource.data = data;
			this.total_linked_users = this.dataSource.filteredData.length
		}, error => {
			if(error.error.error.code){
				this.auth.logout().then(status => {
					window.location.href = environment.appUrl;
				});
			}
		})
	}

	addEdit(linked_user: any) {
		const dialogRef = this.dialog.open(AddEditLinkedUsersComponent,{
			id: 'add-linked-user',
			maxWidth: '600px',
			width: '100%',
			data: {
				linked_user: linked_user
			}
		});
		dialogRef.afterClosed().subscribe((linked_user: any) => {
			this.loadData();
		})
	}


	edit(linked_user: any) {
		const dialogRef = this.dialog.open(AddEditLinkedUsersComponent, {
			id: 'edit-linked-user',
			maxWidth: '360px',
			width: '100%',
			data: {
				linked_user: linked_user
			}
		});
		dialogRef.afterClosed().subscribe((linked_user: any) => {
			this.loadData();
		});
	}


	delete(id: number) {
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'delete-linked-user',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to delete this linked user?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(() => {
			this.linkedUsersService.destroy(id).subscribe(() => {
				dialogRef.close()
				this.loadData();
			});
		});
	}

	ngOnInit(): void {
		this.dataSource.filterPredicate = this.linkedUsersFilter();
	}

}
