<h2 class="popup-heading" mat-dialog-title>Edit Brand Name</h2>
<form>
	<mat-dialog-content class="mat-typography">
		<div class="row">
			<div class="col-md-12">
				<mat-form-field appearance="fill">
					<mat-label>Brand Name</mat-label>
					<input matInput placeholder="Enter Brand Name" [formControl]="brandForm.controls.name" required>
					<mat-error *ngIf="brandForm.controls.name.hasError('required')">
						Brand Name is <strong>required</strong>
					</mat-error>
				</mat-form-field>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button class="cancel-popup" mat-stroked-button mat-dialog-close>Cancel</button>
		<button class="popup-save" mat-flat-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" type="submit" (click)="save()" [disabled]="processing">
			<mat-icon *ngIf="processing">
				<mat-spinner class="accent-spinner" diameter="20"></mat-spinner>
			</mat-icon>
			Save
		</button>
	</mat-dialog-actions>
</form>
