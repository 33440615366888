import { CollaboratorsService } from './../../services/collaborators.service';
import { Collaborators } from './../../interfaces/collaborators';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-collaborator-add-edit',
  templateUrl: './collaborator-add-edit.component.html',
  styleUrls: ['./collaborator-add-edit.component.scss']
})
export class CollaboratorAddEditComponent implements OnInit {
	processing = false;
	collaboratorsUser: any;
	collaboratorsForm: any;
	constructor(private dialogRef: MatDialogRef<CollaboratorAddEditComponent>,
							private fb: UntypedFormBuilder,
							public CollaboratorsService: CollaboratorsService,
							@Inject(MAT_DIALOG_DATA) public data: any,
							public _snackBar: MatSnackBar) {
							this.collaboratorsForm = this.fb.group(this.addEditForm(data.collaborators));
	}

	addEditForm(data: any) {
		return {
			// admin_email: [data.admin_email ?? '', Validators.required],
			email: [data.email ?? '', Validators.required],
		};
	}

	ngOnInit(): void {	}

	save(){
		if(!this.processing && this.collaboratorsForm.valid){
			const collaboratorsUser = this.collaboratorsForm.value as Collaborators;
			if (this.data.collaborators) collaboratorsUser.id = this.data.collaborators.id;
			this.processing = true;
			this.CollaboratorsService.storeOrEdit(collaboratorsUser)
				.subscribe((res: any) => {
					if(res.status){ this.processing = false; this.dialogRef.close(res.collaboratorsUser); this._snackBar.open('Collaborators User updated successfully.', 'Dismiss'); }
				});
		}
	}

}
