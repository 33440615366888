import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { Show } from 'src/app/interfaces/show.interface';
import { ShowsService } from 'src/app/services/shows.service';

@Component({
  selector: 'app-security-code',
  templateUrl: './security-code.component.html',
  styleUrls: ['./security-code.component.scss']
})
export class SecurityCodeComponent implements OnInit {
  show:any;
  securityCode = '';
  constructor(public showService: ShowsService,
    private dialogRef: MatDialogRef<SecurityCodeComponent>,
    @Inject(MAT_DIALOG_DATA) data:any
      ) {
    this.show = data.show;
    if(this.show.show_id){
      this.showService.getSecurityCode(this.show.show_id, this.show.platform).subscribe((response: any) => {
        if(response.status === 'success'){
          this.securityCode = response.security_code;
        }
      });
    }
  }

  ngOnInit(): void {
  }

  close() {
		this.dialogRef.close();
	}
}
