<h2 class="popup-heading" mat-dialog-title>Exclude Emails</h2>
<mat-dialog-content class="mat-typography">
  <mat-form-field class="table-search">
    <mat-label>Exclude Email</mat-label>
    <textarea matInput [disabled]="processing" placeholder="Enter Emails Separated by Commas" [(ngModel)]="emails"></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="cancel-popup" mat-stroked-button mat-dialog-close>Cancle</button>
  <button class="popup-save" mat-flat-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" (click)="confirm()" cdkFocusInitial [disabled]="processing">
    Exclude Emails
    <mat-icon *ngIf="processing">
      <mat-spinner class="accent-spinner" diameter="20"></mat-spinner>
    </mat-icon>
  </button>
</mat-dialog-actions>
