<h2 class="popup-heading" mat-dialog-title>Settings</h2>
<mat-dialog-content class="mat-typography">
	<div class="brands-view">
		<div class="music-box settings-box">
			<h4>Global Audio Alignment</h4>
			<div class="musix-controls">
				<button mat-raised-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" (click)="adjust(false)"> too early</button>
				<h5>{{ ( (adjustment) > 0 ? '+' : '' ) + (adjustment).toFixed(1) + 's' }}</h5>
				<button mat-raised-button [ngStyle]="{'background-color': 'var(--accent-color)', 'color': 'var(--light-color)'}" (click)="adjust(true)"> too late</button>
			</div>
			<p style="text-align: left">Use this setting to apply a global offset if you find your computer is generally off by a defined amount. <span *ngIf="isPyrocast">Please note that {{ siteName }} is currently in BETA and we are continually improving and optimizing the timing accuracy of the system.</span></p>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button class="cancel-popup" mat-stroked-button (click)="cancel()">Cancel</button>
	<button class="popup-save" mat-flat-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" (click)="save()" cdkFocusInitial [disabled]="processing">
		Save
		<mat-icon *ngIf="processing">
			<mat-spinner class="accent-spinner" diameter="20"></mat-spinner>
		</mat-icon>
	</button>
</mat-dialog-actions>
