import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
	message = '';
  constructor(private dialogRef: MatDialogRef<LoadingComponent>,
							@Inject(MAT_DIALOG_DATA) data:any) {
		this.message = data.message;
	}

  ngOnInit(): void {
  }

	cancel() {
		this.dialogRef.close(false);
	}

}
